import React, { useEffect, useState } from 'react';
import {
    TextField,
    ListSubheader,
    List,
    ListItem,
    ListItemText,
    Autocomplete
} from '@mui/material';
import style from './EditForm.module.css';
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from '../../common/icons/trashIcon.svg';
import { setFormData } from "../../service/reducers/formDataEditForms";
import { addGroup, addOkpd, addRegionInGroup, cancelAllPendingRequests, deleteInGroup, deleteOkpd, deleteRegionGroup, searchCompanies } from "../../api/api";
import SearchAutocomplete from "../SearchAutoComplete/SearchAutoComplete";
import { fetchGroupsData } from "../../service/reducers/userGroupsSlice";
import { setFormValidity } from "../../service/reducers/ModalCloseStatusSlice";

const UserEditForm = ({ fields, groupId, validaty }) => {
    const formData = useSelector(state => state.formDataSlice.data);
    const dispatch = useDispatch();
    const [searchResults, setSearchResults] = useState([]);
    const [selectedOkpd, setSelectedOkpd] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedRegions, setSelectedRegions] = useState([]);
    const okpdListData = useSelector((state) => state.okpdUserData.okpdUserData);
    const [showOkpdAutocomplete, setShowOkpdAutocomplete] = useState(false);
    const [showRegionAutocomplete, setShowRegionAutocomplete] = useState(false);
    const groups = useSelector(state => state.groupsData.groupsData);
    const groupData = groups.find(group => group.id === groupId);
    const [defaultCompanyOptions, setDefaultCompanyOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const regionsLkData = useSelector((state) => state.regionsLkData.regionsLkData) || [];
    const companyUserData = useSelector((state) => state.companyUserData.companyUserData);
    const [formErrors, setFormErrors] = useState({});
    const [touchedFields, setTouchedFields] = useState({});

    useEffect(() => {
        if (!validaty) {
            dispatch(setFormValidity(false));
        } else {
            dispatch(setFormValidity(true));
        }
    }, [dispatch, validaty]);

    useEffect(() => {
        if (groupData) {
            const defaultOptions = groupData.type === 'company_suppliers'
                ? companyUserData.filter(company => company.suppliers)
                : groupData.type === 'company_customer'
                    ? companyUserData.filter(company => company.customer)
                    : [];
            setDefaultCompanyOptions(defaultOptions.map(c => `${c.short_name} (${c.inn})`));
        }
    }, [companyUserData, groupData]);

    useEffect(() => {
        if (groupData) {
            if (groupData.type === 'okpd') {
                const okpdData = groupData.okpds.map(okpd => ({
                    code: okpd.okpd_code,
                    name: okpd.okpd_name,
                    level: okpd.level,
                }));
                setSelectedOkpd(okpdData);
            }
            if (groupData.type === 'company_suppliers' || groupData.type === 'company_customer') {
                const companyData = groupData.companies.map(company => ({
                    name: company.short_name,
                    inn: company.inn
                }));
                setSelectedCompanies(companyData);
            }
            if (groupData.type === 'region') {
                const regionData = groupData.regions.map(region => ({
                    iso: region.iso,
                    subjects: region.subjects
                }));
                setSelectedRegions(regionData);
            }
        }
    }, [groupData]);

    const validateForm = () => {
        const errors = {};
        fields.forEach(field => {
            if (field.type === 'input' && field.name !== 'group_name' && !formData[field.name]) {
                errors[field.name] = 'Это поле обязательно к заполнению';
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const addCombobox = (listName) => {
        if (listName === 'okpds') {
            setShowOkpdAutocomplete(true);
        }
        if (listName === 'companies') {
            setShowOkpdAutocomplete(true);
        }
        if (listName === 'regions') {
            setShowRegionAutocomplete(true);
        }
    };

    const handleSearchChange = async (event, newInputValue) => {
        cancelAllPendingRequests();
        if (newInputValue.length > 0) {
            try {
                const results = await searchCompanies(newInputValue);
                if (results.length > 0) {
                    const limitedResults = results.slice(0, 15).map(company => `${company.short_name} (${company.inn})`);
                    setSearchResults(limitedResults);
                } else {
                    setSearchResults([{ short_name: 'Ничего не найдено', id: 'not_found' }]);
                }
            } catch (error) {
                console.error('Ошибка при выполнении поиска:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    useEffect(() => {
        const initialValues = fields.reduce((values, field) => {
            if (field.type === 'select' && field.options.length > 0) {
                values[field.name] = field.value || field.options[0].value;
            } else {
                values[field.name] = field.value || '';
            }
            return values;
        }, {});

        dispatch(setFormData(initialValues));
        // eslint-disable-next-line
    }, [fields, dispatch, groupData]);

    const handleChange = (name, value) => {
        const updatedFormData = { ...formData, [name]: value };
        dispatch(setFormData(updatedFormData));
        validateAndUpdateFormValidity();
    };

    const validateAndUpdateFormValidity = () => {
        const isFormNowValid = validateForm();
        dispatch(setFormValidity(isFormNowValid));
    };

    const handleBlur = (name) => {
        setTouchedFields(prev => ({ ...prev, [name]: true }));
        validateAndUpdateFormValidity();
    };

    const extractInn = (newValue) => {
        const match = newValue.match(/\((\d+)\)/);
        return match ? match[1] : '';
    };

    const handleAutocompleteChange = (event, newValue) => {
        let inn = '';

        if (typeof newValue === 'string') {
            inn = extractInn(newValue);
        }

        const updatedFormData = { ...formData, inn: inn };
        dispatch(setFormData(updatedFormData));
    };

    const formatPhoneNumber = (input) => {
        let value = input.replace(/\D/g, '');
        if (value.startsWith('8')) {
            value = '7' + value.substring(1);
        }
        if (value.length > 11) {
            value = value.substring(0, 11);
        }
        if (value.length <= 1) return '+7';
        if (value.length <= 4) return `+${value}`;
        if (value.length <= 7) return `+${value.substring(0, 1)} ${value.substring(1, 4)} ${value.substring(4)}`;
        if (value.length <= 9) return `+${value.substring(0, 1)} ${value.substring(1, 4)} ${value.substring(4, 7)} ${value.substring(7)}`;
        return `+${value.substring(0, 1)} ${value.substring(1, 4)} ${value.substring(4, 7)} ${value.substring(7, 9)} ${value.substring(9)}`;
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        handleChange('email', email);
    };

    const handlePhoneChange = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        handleChange('phone', formattedPhoneNumber);
    };

    const handleOkpdChange = async (event, newValue) => {
        if (newValue) {
            const newOkpdLevel = newValue.level;
            const isLevelMatch = selectedOkpd.every((okpd) => okpd.level === newOkpdLevel);
            if (isLevelMatch && !selectedOkpd.some(okpd => okpd.okpd_code === newValue.okpd_code)) {
                try {
                    const okpdData = {
                        group_id: groupId,
                        okpd: newValue.okpd_code
                    };
                    await addOkpd(okpdData);
                    dispatch(fetchGroupsData());
                    setSelectedOkpd([...selectedOkpd, newValue]);
                } catch (error) {
                    console.error('Ошибка при добавлении ОКПД:', error);
                }
            }
        }
    };

    const handleDeleteOkpd = async (okpd) => {
        try {
            await deleteOkpd({ group_id: groupId, okpd: okpd.code });
            setSelectedOkpd(currentOkpd => currentOkpd.filter(item => item.code !== okpd.code));
        } catch (error) {
            console.error('Ошибка при удалении ОКПД:', error);
        }
    };

    const handleRegionChange = async (event, newValue) => {
        if (newValue) {
            if (!selectedRegions.some(region => region.iso === newValue.iso)) {
                try {
                    const regionData = {
                        group_id: groupId,
                        region: newValue.iso
                    };
                    await addRegionInGroup(regionData);
                    dispatch(fetchGroupsData());
                    setSelectedRegions([...selectedRegions, newValue]);
                } catch (error) {
                    console.error('Ошибка при добавлении региона:', error);
                }
            }
        }
    };

    const handleDeleteRegion = async (region) => {
        try {
            await deleteRegionGroup({ group_id: groupId, region: region.iso });
            setSelectedRegions(currentRegions => currentRegions.filter(item => item.iso !== region.iso));
        } catch (error) {
            console.error('Ошибка при удалении региона:', error);
        }
    };

    const submitCompanyData = async (inn) => {
        const data = {
            group_id: groupId,
            inn: inn
        };

        try {
            await addGroup(data);
        } catch (error) {
            console.error('Ошибка при отправке данных компании:', error);
        }
    };

    const handleSelectCompany = (event, newValue, reason) => {
        if (reason === 'selectOption') {
            const inn = extractInn(newValue);
            const name = newValue.split(' (')[0];
            const isCompanyAlreadyAdded = selectedCompanies.some(company => company.inn === inn);

            if (!isCompanyAlreadyAdded) {
                setSelectedCompanies([...selectedCompanies, { name, inn }]);
                if (inn) {
                    submitCompanyData(inn);
                }
            }

            setInputValue("");
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        handleSearchChangeTest(event, newInputValue);
    };

    const handleSearchChangeTest = async (event, newInputValue) => {
        cancelAllPendingRequests();
        setInputValue('');

        if (newInputValue.length > 0) {
            try {
                const results = await searchCompanies(newInputValue);
                let filteredResults = [];

                if (results.length > 0) {
                    if (groupData.type === 'company_suppliers') {
                        filteredResults = results.filter(company => company.suppliers);
                    } else if (groupData.type === 'company_customer') {
                        filteredResults = results.filter(company => company.customer);
                    } else {
                        filteredResults = results;
                    }

                    setSearchResults(filteredResults.slice(0, 30));
                } else {
                    setSearchResults([{ short_name: 'Ничего не найдено', id: 'not_found' }]);
                }
            } catch (error) {
                console.error('Ошибка при выполнении поиска:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleDeletInn = async (company) => {
        const inn = company.inn;
        const innData = {
            group_id: groupId,
            inn: inn,
        };

        try {
            await deleteInGroup(innData);
            setSelectedCompanies(currentCompanies =>
                currentCompanies.filter(c => c.inn !== inn)
            );
        } catch (error) {
            console.error('Ошибка при удалении пользователя:', error);
        }
    };

    const renderField = (field) => {
        switch (field.type) {
            case 'input':
                let inputProps = {};
                if (field.name === 'phone') {
                    inputProps = {
                        value: formData[field.name] || '',
                        onChange: handlePhoneChange,
                    };
                } else if (field.name === 'email') {
                    inputProps = {
                        value: formData[field.name] || '',
                        onChange: handleEmailChange,
                    };
                } else if (field.name === 'inn') {
                    return (
                        <SearchAutocomplete
                            options={searchResults}
                            onInputChange={handleSearchChange}
                            onChange={handleAutocompleteChange}
                            placeholder="Введите название компании"
                            noOptionsText="Ничего не найдено"
                        />
                    );
                } else {
                    inputProps = {
                        value: formData[field.name] || groupData?.label || '',
                        onChange: (e) => handleChange(field.name, e.target.value),
                    };
                }
                return (
                    <div key={field.name}>
                        <TextField
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '6px',
                                    backgroundColor: '#F7FAFC',
                                    '& fieldset': {
                                        borderColor: '#8D96B2',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#C8A1FF',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#4A3DFD',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    fontFamily: 'Golos Regular',
                                    fontSize: '14px',
                                },
                                '& .MuiInputBase-input': {
                                    fontFamily: 'Golos Regular',
                                    fontSize: '14px',
                                },
                                '& .MuiFormHelperText-root': {
                                    fontFamily: 'Golos Regular',
                                    fontSize: '14px',
                                },
                            }}
                            className={style.formControl}
                            key={field.name}
                            label={field.label}
                            name={field.name}
                            value={formData[field.name] || ''}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            disabled={!!groupData?.label}
                            onBlur={() => handleBlur(field.name)}
                            error={touchedFields[field.name] && !!formErrors[field.name]}
                            helperText={touchedFields[field.name] && formErrors[field.name]}
                            {...inputProps}
                        />
                    </div>
                );
            case 'companies':
                return (
                    <>
                        <ListSubheader component="div" className={style.listSubheader}>
                            {field.label}
                        </ListSubheader>
                        <List dense className={style.listContainer}>
                            {selectedCompanies.map((company, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <img
                                            onClick={() => handleDeletInn(company)}
                                            className={style.icoDelete}
                                            src={deleteIcon}
                                            alt='delete'
                                        />
                                    }
                                >
                                    <ListItemText primary={`${company.name} (${company.inn})`} />
                                </ListItem>
                            ))}
                            {showOkpdAutocomplete && (
                                <SearchAutocomplete
                                    options={[...defaultCompanyOptions, ...searchResults.map(option => `${option.short_name} (${option.inn})`)]}
                                    onChange={handleSelectCompany}
                                    onInputChange={handleInputChange}
                                    inputValue={inputValue}
                                    placeholder="Введите название компании"
                                    noOptionsText="Ничего не найдено"
                                    value={inputValue}
                                />
                            )}
                            <ListItem button onClick={() => setShowOkpdAutocomplete(true)}>
                                <ListItemText primary="Добавить еще" />
                            </ListItem>
                        </List>
                    </>
                );
            case 'okpdList':
                return (
                    <>
                        <ListSubheader component="div" className={style.listSubheader}>
                            {field.label}
                        </ListSubheader>
                        <List dense className={style.listContainer}>
                            {selectedOkpd.length > 0 && selectedOkpd.map((okpd, index) => (
                                okpd.name && (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <img
                                                onClick={() => handleDeleteOkpd(okpd)}
                                                className={style.icoDelete}
                                                src={deleteIcon}
                                                alt='delete'
                                            />
                                        }
                                    >
                                        <ListItemText primary={`${okpd.code} ${okpd.name}`} />
                                    </ListItem>
                                )
                            ))}
                            {showOkpdAutocomplete && (
                                <Autocomplete
                                    className="autocomplete-custom"
                                    value={null}
                                    onChange={handleOkpdChange}
                                    options={okpdListData}
                                    getOptionLabel={(option) => `${option.okpd_code} ${option.okpd_name}`}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    height: 35,
                                                    padding: '0px',
                                                },
                                                '.MuiInputBase-input': {
                                                    padding: '0px 14px',
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    padding: '2px',
                                                }
                                            }}
                                        />
                                    )}
                                    clearOnEscape
                                    noOptionsText="Ничего не найдено"
                                />
                            )}
                            <ListItem button onClick={() => addCombobox(field.name)}>
                                <ListItemText primary="Добавить еще" />
                            </ListItem>
                        </List>
                    </>
                );
            case 'regions':
                return (
                    <>
                        <ListSubheader component="div" className={style.listSubheader}>
                            {field.label}
                        </ListSubheader>
                        <List dense className={style.listContainer}>
                            {selectedRegions.length > 0 && selectedRegions.map((region, index) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <img
                                            onClick={() => handleDeleteRegion(region)}
                                            className={style.icoDelete}
                                            src={deleteIcon}
                                            alt='delete'
                                        />
                                    }
                                >
                                    <ListItemText primary={`${region.subjects}`} />
                                </ListItem>
                            ))}
                            {showRegionAutocomplete && (
                                <Autocomplete
                                    className="autocomplete-custom"
                                    value={null}
                                    onChange={handleRegionChange}
                                    options={regionsLkData}
                                    getOptionLabel={(option) => option.subjects}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            fullWidth
                                            sx={{
                                                '.MuiInputBase-root': {
                                                    height: 35,
                                                    padding: '0px',
                                                },
                                                '.MuiInputBase-input': {
                                                    padding: '0px 14px',
                                                },
                                                '.MuiOutlinedInput-notchedOutline': {
                                                    padding: '2px',
                                                }
                                            }}
                                        />
                                    )}
                                    clearOnEscape
                                    noOptionsText="Ничего не найдено"
                                />
                            )}
                            <ListItem button onClick={() => addCombobox(field.name)}>
                                <ListItemText primary="Добавить еще" />
                            </ListItem>
                        </List>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className={style.test}>
            {fields.map((field) => renderField(field))}
        </div>
    );
};

export default UserEditForm;
