import axios from 'axios';
import localStorageService from "../service/localStorage/localStorageService";
import {AUTH, BASE_URL, PESONALAREA, PURCHASE} from "./config";

const GRAPH_FIRSTPAGE_URL = '/notifications/';
const GRAPH_SECONDPAGE_URL = '/contract/v1/';
const GRAPH_THIRD_URL = '/procedure/v1/';

const tabConfig = {
    'Извещения': {
        url: GRAPH_FIRSTPAGE_URL,
        regionComboSelect: 'ISO',
        groupParam: 'idGroup',
        searchOrgINNINNs: 'SearchOrgINN',
        searchSuppINNINNINNs: 'SearchSupINN',
        pieParam: 'PlacingWayName',
        pieEtpParam: 'ETPName',
        selectedMonthStacked: 'StartDT',
        monthParam: 'StartDT',
        innSegmentParam: 'CustINN',
        filterOkpd: 'filterOkpd',
        activeRegionsParam: 'CustISO',
        selectedOKPD: 'NewOKPDCode',
        donutRoleV1Param: 'ResponsibleRole',
        isCustomer: 'isCustomer',
        RangeDT: 'RangeDT',
        purchaseCode: 'PurchaseCode',
        selectedZoomableSegment: 'ZoomLvl1',
        endpoints: {
            planeMonth: 'graph/v2/graph_plane_month',
            pieChartEtp: 'graph/v2/graph_placing_name',
            pieChart: 'graph/v2/graph_summary',
            barWithLine: 'graph/v2/graph_publications_month',
            treeMap: 'graph/v2/graph_treemap',
            geo: 'graph/v2/graph_geo',
            okpd1: 'graph/v2/graph_okpd',
            donutKbr: 'graph/v2/graph_kbr_code',
            activity: 'graph/v2/graph_advantages',
            donutRoleLName: 'graph/v2/graph_responsible_role',
            soloLine: 'graph/v2/graph_payments_month',
            manyLines: 'graph/v2/graph_finance_month',
            summary: 'statistic',
            infoStatistics: 'numbers',
            sunBurst: 'graph/v2/zoomable',
            placingMonth: 'graph/v2/graph_placingwayname_month',
        }
    },
    'Контракты': {
        url: GRAPH_SECONDPAGE_URL,
        regionComboSelect: 'ISO',
        groupParam: 'idGroup',
        searchOrgINNINNs: 'SearchCustINN',
        searchSuppINNINNINNs: 'SearchSupINN',
        pieParam: 'BudgetLevelName',
        monthParam: 'ContractSignDate',
        monthParamV1: 'PaymentDate',
        innSegmentParam: 'SupINN',
        filterOkpd: 'filterOkpd',
        activeRegionsParam: 'SupISO',
        selectedOKPD: 'NewOKPDCode',
        donutRoleV1Param: 'SupLegalName',
        isCustomer: 'isCustomer',
        RangeDT: 'RangeDT',
        purchaseCode: 'Status',
        selectedZoomableSegment: 'ZoomLvl1',
        endpoints: {
            pieChart: 'graph/budget_level',
            barWithLine: 'graph/sign_date_month',
            treeMap: 'graph/tree_map',
            geo: 'graph/geo',
            okpd: 'graph/okpd',
            donutKbr: 'graph/status',
            contractBarWithLine: 'graph/payment_month',
            activity: 'graph/contract_placing',
            donutRoleLName: 'graph/legal_name',
            summary: 'statistic',
            infoStatistics: 'numbers',
            sunBurst: 'graph/zoomable'
        }
    },
    'Исполнение': {
        url: GRAPH_THIRD_URL,
        regionComboSelect: 'ISO',
        groupParam: 'idGroup',
        searchOrgINNINNs: 'SearchCustINN',
        searchSuppINNINNINNs: 'SearchSupINN',
        pieParam: 'Status',
        filterOkpd: 'filterOkpd',
        monthParam: 'DocumentDate',
        activeRegionsParam: 'SupISO',
        selectedOKPD: 'NewOKPDCode',
        donutRoleV1Param: 'SupLegalName',
        countryLine: 'ProductOrigincountryFullName',
        isCustomer: 'isCustomer',
        RangeDT: 'RangeDT',
        selectedZoomableSegment: 'ZoomLvl1',
        procedureRegNum: 'ProcedureRegNum',
        endpoints: {
            pieChart: 'graph/status',
            barWithLine: 'graph/document_date_month',
            treeMap: 'graph/tree_map',
            geo: 'graph/geo',
            okpd: 'graph/okpd',
            donutKbr: 'graph/contract_code',
            activity: 'graph/contract_placing',
            donutRoleLName: 'graph/legal_form',
            summary: 'statistic',
            indicator: 'graph/planned',
            sunKey: 'graph/sankey',
            scatter: 'graph/scatter_product',
            sunBurst: 'graph/penalty_lvl',
            okpd1: 'graph/country',
            infoStatistics: 'numbers'
        }
    }
}

const createPayload = (options, tabConfig, activeTab) => {
    const {
        monthParamV1, procedureRegNum, selectedZoomableSegment, purchaseCode,
        isCustomer, searchSuppINNINNINNs, searchOrgINNINNs, groupParam,
        pieParam, monthParam, innSegmentParam, donutRoleV1Param,
        activeRegionsParam, countryLine, filterOkpd, RangeDT, regionComboSelect,pieEtpParam,selectedMonthStacked
    } = tabConfig[activeTab];

    const isNewOKPDCodeEmpty = options.newOKPDCode === undefined ||
        (Array.isArray(options.newOKPDCode) && options.newOKPDCode.length === 0);
    const isTrimCodeEmpty = Array.isArray(options.trimCode) && options.trimCode.length === 0;

    const payload = {
        NewOKPDCode: isNewOKPDCodeEmpty ? options.selectedOkpd : options.newOKPDCode,
        TrimCode: isTrimCodeEmpty ? options.contractTrimCode : options.trimCode,
        ...options.topBody,
        [selectedZoomableSegment]: options.selectedZoomableSegment,
        [regionComboSelect]: options.regionComboSelect,
        [procedureRegNum]: options.regNumArray,
        [purchaseCode]: options.selectedKbrSegments,
        [RangeDT]: options.RangeDT,
        [countryLine]: options.selectedCountryLine,
        [filterOkpd]: options.filterOkpd,
        [groupParam]: options.relatedINNs,
        [searchOrgINNINNs]: options.searchOrgINNINNs,
        [searchSuppINNINNINNs]: options.searchSuppINNINNINNs,
        [pieParam]: options.pieState,
        [pieEtpParam]: options.pieEtp,
        [selectedMonthStacked]: options.selectedMonthStacked,
        [isCustomer]: options.isCustomer,
    };
    if (options.selectedMonth && options.selectedMonth.length > 0) {
        payload[monthParam] = options.selectedMonth;
    } else {
        payload[monthParamV1] = options.selectedContractMonth;
    }

    if (options.searchSuppINNINNINNs && options.searchSuppINNINNINNs.length > 0) {
        payload["CustISO"] = options.activeRegions;
        payload["CustINN"] = options.selectedTreeMapLabels;
        payload["CustLegalName"] = options.selectedDonutSegmetsV1;
    }
    else if (options.selectedOrganization.type === 'company_suppliers') {
        payload["CustISO"] = options.activeRegions;
        payload["CustINN"] = options.selectedTreeMapLabels;
        payload["CustLegalName"] = options.selectedDonutSegmetsV1;
    }
    else {
        if (options.mode === 'org') {
            payload["CustISO"] = options.activeRegions;
        } else if (options.mode === 'cust') {
            payload[activeRegionsParam] = options.activeRegions;
        }

        if (options.treeMapMode === 'org') {
            payload["CustINN"] = options.selectedTreeMapLabels;
        } else if (options.treeMapMode === 'cust') {
            payload[innSegmentParam] = options.selectedTreeMapLabels;
        }

        if (options.roleMode === 'org') {
            payload["CustLegalName"] = options.selectedDonutSegmetsV1;
        } else if (options.roleMode === 'cust') {
            payload[donutRoleV1Param] = options.selectedDonutSegmetsV1;
        }
    }

    return payload;
};


let cancelSources = [];

const cleanPayload = (payload) => {
    const cleanedPayload = {};
    Object.keys(payload).forEach(key => {
        if (payload[key] !== undefined && payload[key] !== null && payload[key] !== '' && !(Array.isArray(payload[key]) && payload[key].length === 0)) {
            cleanedPayload[key] = payload[key];
        }
    });
    return cleanedPayload;
};

const fetchData = (endpoint, options) => {
    const { url } = tabConfig[options.activeTab];
    const payload = createPayload(options, tabConfig, options.activeTab);
    const cleanedPayload = cleanPayload(payload);
    const shouldAddLimitSan = options.activeTab === 'Исполнение' && endpoint === tabConfig['Исполнение'].endpoints.sunKey;
    const shouldAddLimitScatter = endpoint === tabConfig['Исполнение'].endpoints.scatter;
    let limit = null;

    if (shouldAddLimitSan) {
        limit = options.currentLimit || 15;
    } else if (shouldAddLimitScatter) {
        limit = options.currentLimitBubble || 300;
    }

    const source = axios.CancelToken.source();
    cancelSources.push(source);

    return axios.post(
        `${BASE_URL}${url}${endpoint}`,
        cleanedPayload,
        {
            cancelToken: source.token,
            withCredentials: true,
            ...(limit ? { params: { limit } } : {})
        }
    ).catch(error => {
       /* if (error.response && error.response.status === 403) {
            return Promise.reject(new Error('unauthorized'));
        }
        return Promise.reject(error);*/
    });
};

export const cancelAllPendingRequests = () => {
    cancelSources.forEach(source => source.cancel('Operation canceled by user.'));
    cancelSources = [];
};

//role donut
export const fetchRespRole = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.donutRoleLName;
    return fetchData(endpoint, options);
};

export const fetInfoStatistic = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.infoStatistics;
    return fetchData(endpoint, options);
}

//OKPD
export const fetchOkpd = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const okpdEndPoint = endpoints.okpd1;
    return fetchData(okpdEndPoint, options);
};

export const fetchPlacingMonth = (options) => {
    const { endpoints } = tabConfig['Извещения'];
    const placingMonthEndPoint = endpoints.placingMonth;
    return fetchData(placingMonthEndPoint, options);
};

export const fetchGraphPlaneMonth = (options) => {
    const { endpoints } = tabConfig['Извещения'];
    const planeMonthEndPoint = endpoints.planeMonth;
    return fetchData(planeMonthEndPoint, options);
};

export const fetchIndicator = (options) => {
    const { endpoints } = tabConfig['Исполнение'];
    const indicatorEndpoint = endpoints.indicator;
    return fetchData(indicatorEndpoint, { ...options, activeTab: 'Исполнение' });
};
export const fetchSunBurst = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const sunBurstEndpoint = endpoints.sunBurst;
    return fetchData(sunBurstEndpoint, options);
};

export const fetchSunKey = (options) => {
    const { endpoints } = tabConfig['Исполнение'];
    const indicatorEndpoint = endpoints.sunKey;
    return fetchData(indicatorEndpoint, { ...options, activeTab: 'Исполнение' });
};

export const fetchScatter = (options) => {
    const { endpoints } = tabConfig['Исполнение'];
    const scatterEndpoint = endpoints.scatter;
    return fetchData(scatterEndpoint, { ...options, activeTab: 'Исполнение' });
};

export const fetchContractOkpd = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const contractOkpdEndPoint = endpoints.okpd;
    return fetchData(contractOkpdEndPoint, options);
};
//pie chart
export const fetchGraphSummary = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const summaryEndPoint = endpoints.pieChart;
    return fetchData(summaryEndPoint, options);
};

export const fetchPieOtp = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const summaryEndPoint = endpoints.pieChartEtp;
    return fetchData(summaryEndPoint, options);
};

//treemap chart
export const fetchTreeMap = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const treeMapEndpoint = endpoints.treeMap;
    return fetchData(treeMapEndpoint, options);
};

export const fetchActivity = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const activityEndpoint = endpoints.activity;
    const okpdCode = options.activeTab === 'Извещения' ? options.newOKPDCode : options.selectedOkpd;
    const requestTrimCode = options.activeTab === 'Извещения' ? options.trimCode : options.contractTrimCode;
    const payload = createPayload(options, tabConfig, options.activeTab);
    payload.NewOKPDCode = okpdCode;
    payload.TrimCode = requestTrimCode;
    return fetchData(activityEndpoint, {...options, ...payload});
};

//bar with line
export const fetchBarLine = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const barWithLineEndpoint = endpoints.barWithLine;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(barWithLineEndpoint, { ...options, ...payload });
};

//contract bar with line
export const fetchContractBarLine = (options) => {
    const { endpoints } = tabConfig[options.activeTab];
    const contractBarWithLineEndpoint = endpoints.contractBarWithLine;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(contractBarWithLineEndpoint, { ...options, ...payload });
};

//statistics
export const fetchSummary = (options) => {
    const endpoint = 'statistic';
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//kbr donut chart
export const fetchKBR = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.donutKbr;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//geoChart
export const fetchGeo = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.geo;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//solo line chart
export const fetchSoloLine = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.soloLine;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

//many line chart
export const fetchManyLine = (options) => {
    const endpoint = tabConfig[options.activeTab].endpoints.manyLines;
    const payload = createPayload(options, tabConfig, options.activeTab);
    return fetchData(endpoint, { ...options, ...payload });
};

export const fetchColors = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/directory/color`);
        const colors = response.data.result;
        localStorageService.setItem('colors', colors);

        return colors;
    } catch (error) {
        throw error;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${PESONALAREA}/personal-area/users` , {
            withCredentials: true
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchMe = async () => {
    try {
        const response = await axios.get(`${PESONALAREA}/personal-area/me` , {
            withCredentials: true
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const fetchOrganization = async () => {
    try {
        const response = await axios.get(`${PESONALAREA}/personal-area/organization` , {
            withCredentials: true
        });
        return response;
    } catch (error) {
        throw error;
    }
};

export const searchCompanies = async (searchTerm) => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${PESONALAREA}/personal-area/search/company`, {
            cancelToken: source.token,
            params: { search: searchTerm },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const submitStepOne = async (groupData) => {
    try {
        const response = await axios.post(
            `${PESONALAREA}/personal-area/groups/`,
            groupData,
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteGroup = async (group) => {
    try {
        const response = await axios.delete(`${PESONALAREA}/personal-area/groups/`, {
            data: group,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addUser = async (userData) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/groups/user`,
            userData, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addOkpd = async (okpdData) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/groups/okpd`,
            okpdData, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addRegionInGroup = async (region) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/groups/region`,
            region, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteRegionGroup = async (region) => {
    try {
        const response = await axios.delete(`${PESONALAREA}/personal-area/groups/region`, {
            data: region,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const deleteOkpd = async (okpdData) => {
    try {
        const response = await axios.delete(`${PESONALAREA}/personal-area/groups/okpd`, {
            data: okpdData,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (userData) => {
    try {
        const response = await axios.delete(`${PESONALAREA}/personal-area/groups/user`, {
            data: userData,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addGroup = async (groupData) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/groups/inn`,
            groupData, { withCredentials: true });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteInGroup = async (groupData) => {
    try {
        const response = await axios.delete(`${PESONALAREA}/personal-area/groups/inn`, {
            data: groupData,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addAccount = async (accountData) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/users`,
            accountData, { withCredentials: true });
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteAccount = async (userId) => {
    try {
        const url = `${PESONALAREA}/personal-area/users?user_id=${userId}`;
        const response = await axios.delete(url, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (email) => {
    try {
        const response = await axios.post(
            `${AUTH}/reset_password`,
            { email },
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const getOkpd2 = async () => {
    try {
        const response = await axios.get(`${PESONALAREA}/personal-area/search/okpd`, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addOkpdNotInGroup = async (okpd) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/okpd`,
            okpd, { withCredentials: true });
        return response;
    } catch (error) {
        throw error;
    }
};

export const getUserOkpd = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${PESONALAREA}/personal-area/okpd`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUserOkpd = async (okpdCode) => {
    try {
        const url = `${PESONALAREA}/personal-area/okpd`;
        const response = await axios.delete(url, {
            data: { okpd: okpdCode },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteSoloCompany = async (inn) => {
    try {
        const url = `${PESONALAREA}/personal-area/company`;
        const response = await axios.delete(url, {
            data: { inn: inn },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const addCompanyNotInGroup = async (inn) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/company`,
            { inn: inn },
            {
                withCredentials: true
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
};

export const getUserCompany = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${PESONALAREA}/personal-area/company`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при поиске:', error);
        throw error;
    }
};


export const getUserGroups = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${PESONALAREA}/personal-area/me_group`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при получении данных о группах:', error);
    }
};

export const getUserRegion = async () => {
    try {
        const source = axios.CancelToken.source();
        cancelSources.push(source);
        const response = await axios.get(`${PESONALAREA}/personal-area/region`, {
            cancelToken: source.token,
            withCredentials: true
        });
        return response
    } catch (error) {
        console.error('Ошибка при поиске региона:', error);
        throw error;
    }
};

export const deleteUserRegion = async (iso) => {
    try {
        const url = `${PESONALAREA}/personal-area/region`;
        const response = await axios.delete(url, {
            data: { iso: iso },
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при удалении региона', error);
        throw error;
    }
};

export const addRegion = async (region) => {
    try {
        const response = await axios.post(`${PESONALAREA}/personal-area/region`, {
            iso: region.ISO
        }, {
            withCredentials: true
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const getDateForPickers = async () => {
    try {
        const response = await axios.get(`${PESONALAREA}/personal-area/setting`, {
            withCredentials: true
        });
        return response.data.result;
    } catch (error) {
        console.error('Ошибка при получении данных о дате:', error);
    }
};

export const getPurchaseLikes = async () => {
    try {
        const response = await axios.get(`${PURCHASE}/like`, {
            withCredentials: true
        });
        return response.data.result;
    } catch (error) {
        console.error('Ошибка при получении данных о лайках:', error);
    }
};

export const addRangeDate = async (startDate, endDate) => {
    try {
        const response = await axios.post(
            `${PESONALAREA}/personal-area/setting`,
            [
                { type: 'start_date', body: startDate },
                { type: 'end_date', body: endDate }
            ],
            {
                withCredentials: true
            }
        );
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};

export const testSearch = async (queryParams) => {
    try {
        const response = await axios.post(
            `${PURCHASE}`,
            queryParams,
            {
                withCredentials: true
            }
        );
        return response;
    } catch (error) {
        console.error('Ошибка при отправке данных:', error);
        throw error;
    }
};
