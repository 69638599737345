import * as d3 from 'd3';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import styles from "../LineBarChart/LineBarChart.module.css";
import { useDispatch, useSelector } from "react-redux";
import useResizeObserver from "use-resize-observer";
import { fetchStackedWithContentData } from "../../../service/reducers/StackedWithContent/StackedWithContentSlice";
import useTooltip from "../../../hook/useTooltip";
import icons from "../../../common/icons/icons";
import useFetchData from "../../../hook/useFetchData";
import {
    addBarLineV1Name,
    clearSelectedMonth, clearSelectedPeriod,
    setCurrentActiveButton, toggleSelectedMonthsGroup
} from "../../../service/reducers/StackedWithContent/StackedWithContentSegmentSlice";
import { clearSelectedMonth as clearBarWithLineMonth } from "../../../service/reducers/PublishChart/BarWithLineChartMonthSlice";
import { formatCurrency } from "../../../utils/rubbleFunc";
import { formatNumber } from "../../../utils/countFunc";
import { cancelAllPendingRequests } from "../../../api/api";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import Spinner from "../../TestPages/Spinner";
import tooltipNames from "../../../utils/tooltipTitles.json"
import Tooltip from "../../../components/Tooltip/Tooltip";
import Legend from "../../../components/DiagrammLegend/Legend";
import {threeColors} from "../../../utils/colors";

export const StackedWithContent = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { StackedWithContentData, loading } = useSelector((state) => state.stackedWithContentSlice);
    const { selectedMonth } = useSelector((state) => state.stackedWithContentSegmentSlice);
    const { currentActiveButton } = useSelector((state) => state.stackedWithContentSegmentSlice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const headerTitle = 'План/факт по суммам извещений и контрактов'

    const headerWithThreeButtons = useMemo(() => ({
        title: headerTitle,
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    }), [headerTitle, zoomedDiagram]);

    useFetchData(fetchStackedWithContentData, [
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position),
        useSelector(state => state.treeMapSlice.selectedSegments),
    ], 'stackedWithContent');

    useEffect(() => {
        if (StackedWithContentData && width && height && loading === 'successful') {
            const groupedData = groupDataByTime(StackedWithContentData, currentActiveButton);
            drawChart(groupedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [StackedWithContentData, currentActiveButton, width, height, selectedMonth]);

    const setGroupAndClearSelection = useCallback((newGroupType) => {
        dispatch(setCurrentActiveButton(newGroupType));
        if (selectedMonth && selectedMonth.length > 0) {
            dispatch(clearSelectedMonth());
            dispatch(clearSelectedPeriod());
        }
    }, [dispatch, selectedMonth]);

    const groupDataByTime = (data, groupType) => {
        let groupedData = [];
        let currentGroup = null;
        let sumNotifications = 0;
        let sumContracts = 0;
        let countItems = 0;
        let arrayOfMonths = [];
        let arrayOfExtras = [];

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const itemDate = new Date(item.month);
            const itemYear = itemDate.getFullYear();
            let groupKey;

            if (groupType === 'month') {
                groupKey = `${itemYear}-${itemDate.getMonth()}`;
            } else if (groupType === 'quarter') {
                const quarter = Math.floor(itemDate.getMonth() / 3) + 1;
                groupKey = `${quarter}КВ-${itemYear}`;
            } else if (groupType === 'half-year') {
                groupKey = ((itemDate.getMonth() < 6) ? 'П1-' : 'П2-') + itemYear;
            } else {
                groupKey = String(itemYear);
            }

            if (currentGroup !== groupKey) {
                if (currentGroup !== null) {
                    groupedData.push({
                        label: currentGroup,
                        month: data[i - 1].month,
                        summaryNotifications: sumNotifications,
                        summaryContracts: sumContracts,
                        count: countItems,
                        months: [...arrayOfMonths],
                        extras: [...arrayOfExtras]
                    });
                }
                arrayOfMonths = [];
                arrayOfExtras = [];
                currentGroup = groupKey;
                sumNotifications = 0;
                sumContracts = 0;
                countItems = 0;
            }
            arrayOfExtras.push(item.extra);
            arrayOfMonths.push(data[i].month);
            sumNotifications += item.summary;
            const contractValue = item.extra.find(e => e.label === 'sum_contract').value;
            sumContracts += contractValue;
            countItems += item.count;
        }

        if (currentGroup !== null) {
            groupedData.push({
                label: currentGroup,
                month: data[data.length - 1].month,
                summaryNotifications: sumNotifications,
                summaryContracts: sumContracts,
                count: countItems,
                months: [...arrayOfMonths],
                extras: [...arrayOfExtras]
            });
        }
        return groupedData;
    };

    const formatDateByGroupType = (dateString, groupType) => {
        const date = new Date(dateString);
        switch (groupType) {
            case 'month':
                return date.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' });
            case 'quarter':
                const quarter = Math.floor(date.getMonth() / 3) + 1;
                return `${quarter} Квартал ${date.getFullYear()} г.`;
            case 'half-year':
                const half = date.getMonth() < 6 ? '1 полугодие' : '2 полугодие';
                return `${half} ${date.getFullYear()} г.`;
            case 'year':
                const year = date.getFullYear();
                return `${year} г.`;
            default:
                return date.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' });
        }
    };

    const tooltipConfig = tooltipNames.StackedWithContent.Tabs[activeTab];
    const onMouseMoveBar = (event, d) => {
        const tooltipData = [
            { label: tooltipConfig.label, value: formatDateByGroupType(d.month, currentActiveButton) },
            { label: tooltipConfig.value, value: formatCurrency(d.summaryNotifications)},
            { label: tooltipConfig.value2, value: formatCurrency(d.summaryContracts)}
        ];

        showTooltip(event, tooltipData);
    };

    const onMouseOut = () => {
        hideTooltip();
    };

    const drawChart = (data) => {
        if (!data || !ref.current) return;
        d3.select(ref.current).select("svg").remove();
        const margin = { top: 10, right: 50, bottom: 100, left: 55 };
        const width = ref.current.clientWidth;
        const height = ref.current.clientHeight;
        const minBarHeight = 2;
        const minSegmentHeight = 3;
        const x = d3.scaleBand()
            .domain(data.map(d => new Date(d.month)))
            .rangeRound([margin.left, width - margin.right])
            .padding(0.2);
        const y1 = d3.scaleLinear()
            .domain([0, d3.max(data, d => Math.max(d.summaryNotifications, d.summaryContracts))])
            .rangeRound([height - margin.bottom, margin.top]);
        const maxY = d3.max(data, d => Math.max(d.summaryNotifications, d.summaryContracts));
        const tickValues = [maxY / 4, maxY / 2, 3 * maxY / 4, maxY];

        const svg = d3.select(ref.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height]);

        let lastYear = null;
        const xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).tickSizeOuter(0))
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .selectAll(".tick")
            .each(function (d, i) {
                d3.select(this).select("text").remove();
                const correspondingData = data.find(item => new Date(item.month).getTime() === new Date(d).getTime());
                if (correspondingData) {
                    const date = new Date(correspondingData.month);
                    const currentYear = date.getFullYear();
                    if (currentActiveButton === 'month') {
                        const month = date.toLocaleString('ru', { month: 'short' }).replace('.', '');
                        d3.select(this).append("text")
                            .attr("dx", "-2em")
                            .attr("dy", "0.3em")
                            .style("text-anchor", "middle")
                            .attr("transform", "rotate(-90)")
                            .style("fill", "#8D96B2")
                            .style("font-family", "Golos Regular")
                            .text(month);
                    } else if (currentActiveButton === 'quarter') {
                        const quarter = Math.floor(date.getMonth() / 3) + 1;
                        d3.select(this).append("text")
                            .attr("dx", "-2em")
                            .attr("dy", "0.3em")
                            .style("text-anchor", "middle")
                            .attr("transform", "rotate(-90)")
                            .style("font-family", "Golos Regular")
                            .style("fill", "#8D96B2")
                            .text(`КВ${quarter}`);
                    } else if (currentActiveButton === 'half-year') {
                        const half = date.getMonth() < 6 ? '1П' : '2П';
                        d3.select(this).append("text")
                            .attr("dx", "-2em")
                            .attr("dy", "0.3em")
                            .style("text-anchor", "middle")
                            .attr("transform", "rotate(-90)")
                            .style("font-family", "Golos Regular")
                            .style("fill", "#8D96B2")
                            .text(half);
                    }
                    if (lastYear !== currentYear) {
                        d3.select(this).append("text")
                            .attr("dy", "4.5em")
                            .style("text-anchor", "middle")
                            .style("fill", "#8D96B2")
                            .style("font-family", "Golos Regular")
                            .style("font-size", `${Math.min(12, width / 30)}px`)
                            .text(currentYear);
                        lastYear = currentYear;
                    }
                }
            });

        const y1Axis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y1)
                .tickValues(tickValues)
                .tickFormat(formatNumber)
            )
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick text").style("fill", "#8D96B2"))
            .call(g => g.selectAll(".tick text").style("font-family", "Golos Regular"));

        const initialOpacity = selectedMonth.length === 0 ? 1 : d => selectedMonth.includes(d.month) ? 1 : 0.3;

        const roundedRectangle = (x, y, width, height, maxRadius) => {
            const radius = Math.min(maxRadius, height / 2);
            return `M${x + radius},${y}
            L${x + width - radius},${y}
            Q${x + width},${y} ${x + width},${y + radius}
            L${x + width},${y + height}
            L${x},${y + height}
            L${x},${y + radius}
            Q${x},${y} ${x + radius},${y}
            Z`;
        };

        const bars = svg.append("g")
            .selectAll("g")
            .data(data)
            .join("g");

        bars.append("path")
            .attr("d", d => {
                if (d.summaryContracts > d.summaryNotifications) {
                    return roundedRectangle(
                        x(new Date(d.month)),
                        y1(d.summaryContracts),
                        x.bandwidth(),
                        Math.max(y1(0) - y1(d.summaryContracts), minBarHeight),
                        5
                    );
                } else {
                    return roundedRectangle(
                        x(new Date(d.month)),
                        y1(d.summaryNotifications),
                        x.bandwidth(),
                        Math.max(y1(0) - y1(d.summaryNotifications), minBarHeight),
                        5
                    );
                }
            })
            .attr("fill", d => d.summaryContracts > d.summaryNotifications ? "#4B72FB" : "#AB70FF")
            .attr("opacity", initialOpacity)
            .on("click", function (event, d) {
                cancelAllPendingRequests();
                dispatch(toggleSelectedMonthsGroup({ months: d.months, timePeriod: d.label }));
                dispatch(addBarLineV1Name(headerTitle));
                dispatch(clearBarWithLineMonth())
                onMouseOut();
            });

        bars.append("path")
            .attr("d", d => {
                if (d.summaryContracts > d.summaryNotifications) {
                    const contractHeight = y1(d.summaryNotifications) - y1(d.summaryContracts);
                    return roundedRectangle(
                        x(new Date(d.month)),
                        y1(d.summaryContracts),
                        x.bandwidth(),
                        Math.max(contractHeight, minSegmentHeight),
                        5
                    );
                } else if (d.summaryNotifications > d.summaryContracts) {
                    const notificationHeight = y1(d.summaryContracts) - y1(d.summaryNotifications);
                    return roundedRectangle(
                        x(new Date(d.month)),
                        y1(d.summaryNotifications),
                        x.bandwidth(),
                        Math.max(notificationHeight, minSegmentHeight),
                        5
                    );
                }
                return null;
            })
            .attr("fill", d => d.summaryContracts > d.summaryNotifications ? "#E94F74" : "#4B72FB")
            .attr("opacity", initialOpacity)
            .on("click", function (event, d) {
                cancelAllPendingRequests();
                dispatch(toggleSelectedMonthsGroup({ months: d.months, timePeriod: d.label }));
                dispatch(addBarLineV1Name(headerTitle));
                dispatch(clearBarWithLineMonth())
                onMouseOut();
            });

        bars.on("mousemove", function (event, d) {
            onMouseMoveBar(event, d);
        }).on("mouseout", onMouseOut);

        bars.transition()
            .duration(1000)
            .attr("y", d => Math.min(y1(d.summaryNotifications), y1(0) - minBarHeight))
            .attr("height", d => Math.max(y1(0) - y1(d.summaryNotifications), minBarHeight));

        svg.append("g").call(xAxis);
        svg.append("g").call(y1Axis);
    }

    const legendData = [
        { label: "Сумма НМЦК закупок" },
        { label: "Сумма контрактов", },
        { label: "Превышает сумму контрактов",},
    ];

    return (
        <div className={`${style.container} ${selectedMonth.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "700px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    diagramName={headerTitle}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={headerTitle} data={legendData} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={threeColors} onLegendItemClick={onMouseOut} selectedSlice={[]}/>
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                    <div className={styles.buttonContainter}>
                        <div
                            className={`${styles.button} ${currentActiveButton === 'month' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupAndClearSelection('month')}
                        >
                            Месяц
                        </div>
                        <div
                            className={`${styles.button} ${currentActiveButton === 'quarter' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupAndClearSelection('quarter')}
                        >
                            Квартал
                        </div>
                        <div
                            className={`${styles.button} ${currentActiveButton === 'half-year' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupAndClearSelection('half-year')}
                        >
                            Полгода
                        </div>
                        <div
                            className={`${styles.button} ${currentActiveButton === 'year' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupAndClearSelection('year')}
                        >
                            Год
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default StackedWithContent;
