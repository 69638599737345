import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import { useDispatch, useSelector } from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import { formatNumber } from "../../../utils/countFunc";
import styles from "../LineBarChart/LineBarChart.module.css";
import { fetchManyLineMonthData, setShowCountManyLines } from "../../../service/reducers/ManyLinesMonthSlice";
import localStorageService from "../../../service/localStorage/localStorageService";
import { formatCurrency } from "../../../utils/rubbleFunc";
import tooltipNames from "../../../utils/tooltipTitles.json";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from "../../../hook/useTooltip";
import Tooltip from "../../../components/Tooltip/Tooltip";

const ManyLineMonthD = ({ onZoomClick, zoomedDiagram }) => {
    const [groupType, setGroupType] = useState('month');
    const dispatch = useDispatch();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const { manyLineMonthData, loading, showCount } = useSelector((state) => state.manyLineChartMonthSlice);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const isBrushActive = useSelector((state) => state.manyLineChartMonthSlice.isBrushActiveMonth);
    const headerWithThreeButtons = {
        title: "Динамика размещения закупок по способам определения поставщика",
        icons: [
            { name: 'inside', icon: icons.inside, activeIcon: icons.insideActive, width: 20, height: 20 },
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'change', icon: icons.change, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 },
        ]
    };

    useFetchData(fetchManyLineMonthData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.region.activeRegions),
        useSelector(state => state.activitySlice),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.donutRolesSlice.selectedSegments),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position)
    ]);

    const fillMissingMonths = (data) => {
        const dateFormat = d3.timeParse("%Y-%m-%dT%H:%M:%S%Z");
        const dataByLabel = d3.group(data, d => d.label);

        const allMonths = d3.timeMonths(
            d3.min(data, d => dateFormat(d.month)),
            d3.max(data, d => new Date(dateFormat(d.month).getFullYear(), dateFormat(d.month).getMonth() + 1, 0))
        );

        const filledData = [];

        dataByLabel.forEach((values, label) => {
            const codeValue = values[0].extra.find(e => e.label === "code")?.value;

            allMonths.forEach(month => {
                const existingEntry = values.find(value =>
                    dateFormat(value.month).getMonth() === month.getMonth() &&
                    dateFormat(value.month).getFullYear() === month.getFullYear()
                );

                if (existingEntry) {
                    filledData.push(existingEntry);
                } else {
                    filledData.push({
                        label,
                        month: month.toISOString(),
                        summary: 0,
                        count: 0,
                        extra: [
                            {
                                label: "code",
                                value: codeValue || "UNKNOWN"
                            }
                        ]
                    });
                }
            });
        });

        return filledData;
    };

    useEffect(() => {
        if (loading === 'successful' && width && height && manyLineMonthData) {
            const filledData = fillMissingMonths(manyLineMonthData);
            const groupedData = groupDataByTime(filledData, groupType);
            createManyLineChart(groupedData);
        }
        // eslint-disable-next-line
    }, [width, height, manyLineMonthData, groupType, isBrushActive, showCount]);

    const groupDataByTime = (data, groupType) => {
        let groupedData = [];
        let currentGroup = null;
        let sum = 0;
        let countItems = 0;
        let currentLabel = null;
        let currentCode = null;

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const itemDate = new Date(item.month);
            const itemYear = itemDate.getFullYear();
            let groupKey;
            const itemCode = item.extra.find(e => e.label === "code")?.value;

            if (groupType === 'month') {
                groupKey = itemYear + '-' + (itemDate.getMonth() + 1).toString().padStart(2, '0');
            } else if (groupType === 'quarter') {
                const quarter = Math.floor(itemDate.getMonth() / 3) + 1;
                groupKey = `${quarter}КВ-${itemYear}`;
            } else if (groupType === 'half-year') {
                groupKey = ((itemDate.getMonth() < 6) ? 'П1-' : 'П2-') + itemYear;
            } else {
                groupKey = String(itemYear);
            }

            if (currentGroup !== groupKey) {
                if (currentGroup !== null) {
                    groupedData.push({
                        label: currentLabel,
                        month: data[i - 1].month,
                        summary: sum,
                        count: countItems,
                        extra: [{ label: "code", value: currentCode }]
                    });
                }
                currentGroup = groupKey;
                currentLabel = item.label;
                currentCode = itemCode;
                sum = 0;
                countItems = 0;
            }
            sum += item.summary;
            countItems += item.count;
        }

        if (currentGroup !== null) {
            groupedData.push({
                label: currentLabel,
                month: data[data.length - 1].month,
                summary: sum,
                count: countItems,
                extra: [{ label: "code", value: currentCode }]
            });
        }
        return groupedData;
    };

    const formatDateByGroupType = (dateString, groupType) => {
        const date = new Date(dateString);
        switch (groupType) {
            case 'month':
                return date.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' });
            case 'quarter':
                const quarter = Math.floor(date.getMonth() / 3) + 1;
                return `${quarter} Квартал ${date.getFullYear()} г.`;
            case 'half-year':
                const half = date.getMonth() < 6 ? '1 полугодие' : '2 полугодие';
                return `${half} ${date.getFullYear()} г.`;
            case 'year':
                const year = date.getFullYear();
                return `${year} г.`;
            default:
                return date.toLocaleDateString('ru-RU');
        }
    };

    const onMouseMove = (event, d) => {
        const formattedMonth = formatDateByGroupType(d.month, groupType);
        const tooltipData = [
            { label: tooltipNames.ManyLinesNotification.date, value: formattedMonth },
            { label: tooltipNames.ManyLinesNotification.label, value: d.label },
            { label: tooltipNames.ManyLinesNotification.summary, value: formatCurrency(d.summary) },
            { label: tooltipNames.ManyLinesNotification.count, value: `${d.count} шт` },
        ];
        showTooltip(event, tooltipData);
    };

    const createManyLineChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const margin = { top: 30, right: 30, bottom: 100, left: 55 };
        const effectiveWidth = width - margin.left - margin.right;
        const effectiveHeight = height - margin.top - margin.bottom;
        const activeParam = showCount === 'sum' ? 'summary' : 'count';

        const loadColors = () => {
            const colorsData = localStorageService.getItem('colors');
            const colorMap = new Map();
            colorsData.forEach(item => {
                colorMap.set(item.code, item.color);
            });
            return colorMap;
        };

        const colorMap = loadColors();

        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height)
            .append("g")
            .attr("transform", `translate(${margin.left}, ${margin.top})`);

        const allValues = data.map(d => d[activeParam]);
        const yScale = d3.scaleLinear()
            .domain([0, Math.max(...allValues)])
            .range([effectiveHeight, 0]);

        const allMonths = [...new Set(data.map(d => {
            const date = new Date(d.month);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        }))];

        const xScale = d3.scalePoint()
            .domain(allMonths)
            .range([0, effectiveWidth]);

        const formatDateForScale = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            return `${year}-${month}`;
        };

        const maxVal = Math.max(...allValues);
        let customTicks;

        if (showCount === 'count') {
            const step = Math.max(Math.round(maxVal / 3), 1);
            customTicks = [1, 1 + step, 1 + 2 * step, maxVal];
        } else {
            customTicks = Array.from({ length: 4 }, (_, i) => ((i + 1) * maxVal / 4));
        }

        const setTickText = (d, groupType) => {
            const date = new Date(d);
            switch (groupType) {
                case 'month':
                    return date.toLocaleString('ru', { month: 'short' }).replace('.', '');
                case 'quarter':
                    const quarter = Math.floor(date.getMonth() / 3) + 1;
                    return `КВ${quarter}`;
                case 'half-year':
                    return date.getMonth() < 6 ? '1П' : '2П';
                case 'year':
                    return date.getFullYear();
                default:
                    return '';
            }
        };

        let lastYear = null;
        const xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom - 30})`)
            .call(d3.axisBottom(xScale).tickSizeOuter(0))
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .selectAll(".tick")
            .each(function (d, i) {
                d3.select(this).select("text").remove();
                const correspondingData = data.find(item => {
                    const itemDate = new Date(item.month);
                    const tickDate = new Date(d);
                    return itemDate.getFullYear() === tickDate.getFullYear() && itemDate.getMonth() === tickDate.getMonth();
                });
                const date = new Date(correspondingData.month);
                const currentYear = date.getFullYear();
                if (correspondingData) {
                    const tickText = setTickText(correspondingData.month, groupType);
                    d3.select(this).append("text")
                        .attr("dx", "-2em")
                        .attr("dy", "0.3em")
                        .style("text-anchor", "middle")
                        .attr("transform", "rotate(-90)")
                        .style("fill", "#8D96B2")
                        .style("font-family", "Golos Regular")
                        .text(tickText);
                }
                if (lastYear !== currentYear) {
                    const yearText = d3.select(this).append("text")
                        .attr("dx", "0.5em")
                        .text(currentYear)
                        .style("fill", "#8D96B2")
                        .style("font-family", "Golos Regular")
                        .style("font-size", `${Math.min(12, width / 30)}px`);
                    const yearTextSize = yearText.node().getBBox();
                    yearText.attr("dy", `${yearTextSize.height + 30}`);
                    lastYear = currentYear;
                }
            });

        const yAxis = d3.axisLeft(yScale).tickValues(customTicks).tickSize(0).tickFormat((d) => formatNumber(d));

        svg.append("g")
            .call(xAxis);

        const yAxisGroup = svg.append("g").call(yAxis);
        yAxisGroup.selectAll("text").attr("fill", "#8D96B2").attr("transform", "translate(-6, 0)");
        yAxisGroup.selectAll(".domain").attr("stroke", "#EDF1F5");

        const drawArea = (data, color) => {
            const area = d3.area()
                .x(d => xScale(formatDateForScale(new Date(d.month))))
                .y0(effectiveHeight)
                .curve(d3.curveMonotoneX)
                .y1(d => yScale(d[activeParam]));

            svg.append("path")
                .datum(data)
                .attr("fill", color)
                .attr("opacity", 0.3)
                .attr("d", area)
                .attr("fill-opacity", 0)
                .style("pointer-events", "none")
                .transition()
                .duration(750)
                .attr("fill-opacity", 0.8);
        };

        const drawLineAndInteractionLayer = (data, color) => {
            const line = d3.line()
                .curve(d3.curveMonotoneX)
                .x(d => xScale(formatDateForScale(new Date(d.month))))
                .y(d => yScale(d[activeParam]));
            const visiblePath = svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", color)
                .attr("stroke-width", 1.5)
                .attr("d", line);
            const lineLength = visiblePath.node().getTotalLength();
            visiblePath
                .attr("stroke-dasharray", `${lineLength} ${lineLength}`)
                .attr("stroke-dashoffset", lineLength)
                .transition()
                .duration(1000)
                .attr("stroke-dashoffset", 0);

            svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", "transparent")
                .attr("stroke-width", 20)
                .attr("d", line)
                .on("mousemove", function (event) {
                    const [x] = d3.pointer(event, this);
                    const domain = xScale.domain();
                    const range = xScale.range();
                    const rangePoints = d3.range(range[0], range[1], xScale.step());
                    const index = d3.bisect(rangePoints, x) - 1;
                    const dataIndex = Math.max(0, Math.min(domain.length - 1, index));
                    const nearestDataPoint = data[dataIndex];

                    if (nearestDataPoint) {
                        onMouseMove(event, nearestDataPoint);
                    }
                })
                .on("mouseout", hideTooltip);
        };

        const dataByLabel = d3.group(data, d => d.label);
        // eslint-disable-next-line
        let index = 0;
        dataByLabel.forEach((values, label) => {
            const codeValue = values[0].extra.find(e => e.label === "code")?.value;
            const color = colorMap.get(codeValue) || "#ccc";
            drawArea(values, color);
            drawLineAndInteractionLayer(values, color);
            index++;
        });

        const handleBrushEnd = (event) => {
            const selection = event.selection;
            if (!selection) {
                return;
            }
            const [x0, x1] = selection.map(d => {
                const dates = xScale.domain();
                const range = xScale.range();
                const rangePoints = d3.range(range[0], range[1], xScale.step());
                const i = d3.bisect(rangePoints, d);
                return new Date(dates[i - 1]);
            });

            const filteredData = data.filter(d => {
                const date = new Date(d.month);
                return date >= x0 && date <= x1;
            });

            createManyLineChart(filteredData);
        };

        let brush;

        const handleRightClick = (event) => {
            event.preventDefault();
            if (brush) {
                d3.select(".brush").call(brush.move, null);
            }
            const filledData = fillMissingMonths(manyLineMonthData);
            const groupedData = groupDataByTime(filledData, groupType);
            const initialData = groupDataByTime(groupedData, groupType);
            createManyLineChart(initialData);
        };

        if (isBrushActive) {
            brush = d3.brushX()
                .extent([[0, 0], [effectiveWidth, effectiveHeight]])
                .on("end", handleBrushEnd);

            svg.append("g")
                .attr("class", "brush")
                .call(brush)
                .on("contextmenu", handleRightClick);
        }
    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "700px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    diagramId={"Brush2"}
                    diagramName={headerWithThreeButtons.title}
                    activeMode={showCount}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'sum') {
                            dispatch(setShowCountManyLines('sum'));
                        } else if (mode === 'count') {
                            dispatch(setShowCountManyLines('count'));
                        }
                    }}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`} />
                    <div className={styles.buttonContainter}>
                        <div
                            className={`${styles.button} ${groupType === 'month' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('month')}
                        >
                            Месяц
                        </div>
                        <div
                            className={`${styles.button} ${groupType === 'quarter' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('quarter')}
                        >
                            Квартал
                        </div>
                        <div
                            className={`${styles.button} ${groupType === 'half-year' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('half-year')}
                        >
                            Погулодие
                        </div>
                        <div
                            className={`${styles.button} ${groupType === 'year' ? styles.buttonSelected : ''}`}
                            onClick={() => setGroupType('year')}
                        >
                            Год
                        </div>
                    </div>
                    <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
                </>
            )}
        </div>
    );
};

export default ManyLineMonthD;
