import React, { useEffect, useRef, useState, useMemo } from 'react';
import styles from './GeoChart.module.css';
import { ReactComponent as MapSvg } from '../../../utils/GeoSvg.svg';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import { useDispatch, useSelector } from "react-redux";
import {
    addGeoName,
    clearActiveRegions,
    setMode,
    toggleRegion
} from "../../../service/reducers/GeoChart/GeoChartRegionSlice";
import { fetchGeoData } from "../../../service/reducers/GeoChart/GeoChartSlice";
import { formatCurrency } from "../../../utils/rubbleFunc";
import { formatNumberWithDecimal } from "../../../utils/countFunc";
import * as d3 from 'd3';
import { cancelAllPendingRequests } from "../../../api/api";
import Legend from "../../../components/DiagrammLegend/Legend";
import { setActiveLegendItem } from "../../../service/reducers/legendItemsClick";
import regionData from "../../../utils/geoNamesMap.json";
import tooltipNames from "../../../utils/tooltipTitles.json";
import useFetchData from "../../../hook/useFetchData";
import useTooltip from '../../../hook/useTooltip';
import Tooltip from '../../../components/Tooltip/Tooltip';

function interpolateColor(color1, color2, factor, nonlinear = false) {
    const r1 = parseInt(color1.substring(1, 3), 16);
    const g1 = parseInt(color1.substring(3, 5), 16);
    const b1 = parseInt(color1.substring(5, 7), 16);

    const r2 = parseInt(color2.substring(1, 3), 16);
    const g2 = parseInt(color2.substring(3, 5), 16);
    const b2 = parseInt(color2.substring(5, 7), 16);

    const adjustedFactor = nonlinear ? Math.sqrt(factor) : factor;

    const r = Math.round(r1 + adjustedFactor * (r2 - r1));
    const g = Math.round(g1 + adjustedFactor * (g2 - g1));
    const b = Math.round(b1 + adjustedFactor * (b2 - b1));

    return `#${("0" + r.toString(16)).slice(-2)}${("0" + g.toString(16)).slice(-2)}${("0" + b.toString(16)).slice(-2)}`;
}

const numSegments = 89;
const colorStart = '#CDDFFE';
const colorEnd = '#4F43F9';
const gradientColors = Array.from({ length: numSegments }, (_, i) => {
    return interpolateColor(colorStart, colorEnd, i / (numSegments - 1));
});

const ColorScale = ({ min, max, height }) => {
    const segmentHeight = Math.floor(height / gradientColors.length);
    return (
        <div className={styles.colorScaleContainer}>
            <div>{formatNumberWithDecimal(max)}</div>
            {[...gradientColors].reverse().map((color, index) => (
                <div
                    key={index}
                    className={styles.colorBox}
                    style={{ backgroundColor: color, height: `${segmentHeight}px` }}
                />
            ))}
            <div
                style={{ visibility: min === max ? 'hidden' : 'visible' }}
            >
                {formatNumberWithDecimal(min)}
            </div>
        </div>
    );
};

export const GeoChart = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const { tooltip, tooltipRef, showTooltip, hideTooltip } = useTooltip();
    const [paths, setPaths] = useState(null);
    const { GeoData } = useSelector((state) => state.geoSlice);
    const viewBox = useSelector((state) => state.region.viewBox);
    const min = GeoData.length > 0 ? Math.min(...GeoData.map(d => d.value)) : 0;
    const max = GeoData.length > 0 ? Math.max(...GeoData.map(d => d.value)) : 0;
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const memoizedActiveRegions = useMemo(() => activeRegions || [], [activeRegions]);
    const selectedRegions = useMemo(() => regionData.filter(region => activeRegions.includes(region.ISO)).map(region => region.region), [activeRegions]);
    const mode = useSelector((state) => state.region.mode);
    const geoChartRef = useRef(null);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const regionComboSelect = useSelector((state) => state.regionComboSelect.regionComboData);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || selectedOrganization.type === 'region' || filterOkpd.length > 0 || regionComboSelect.length > 0) && activeTab !== 'Извещения';
    let headerTitle;
    if (activeTab === 'Извещения') {
        headerTitle = 'Анализ географии заказчиков';
    } else if ((selectedOrganization.type === 'company_customer' || slidePoz === 'supplier') && activeTab !== 'Извещения') {
        headerTitle = 'Анализ географии исполнителей';
    } else if ((selectedOrganization.type === 'company_suppliers' || slidePoz === 'customer') && activeTab !== 'Извещения') {
        headerTitle = 'Анализ географии заказчиков';
    } else if (shouldShowChangeButton && mode === '') {
        headerTitle = 'Анализ географии исполнителей';
    } else {
        switch (mode) {
            case 'cust':
                headerTitle = 'Анализ географии исполнителей';
                break;
            case 'org':
                headerTitle = 'Анализ географии заказчиков';
                break;
            default:
                if (activeTab === 'Исполнение') {
                    headerTitle = 'Анализ географии заказчиков';
                } else {
                    headerTitle = 'Анализ географии исполнителей';
                }
        }
    }

    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            ...shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : [],
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 },
        ]
    };
    const [colorBoxHeight, setColorBoxHeight] = useState(0);
    const zoomRef = useRef();
    const [cityNamesMap, setCityNamesMap] = useState({});

    useEffect(() => {
        const map = regionData.reduce((acc, { ISO, region }) => {
            acc[ISO] = region;
            return acc;
        }, {});
        setCityNamesMap(map);
    }, []);

    const setupZoom = () => {
        const svg = d3.select(geoChartRef.current);
        const width = +svg.attr('width') || svg.node().getBoundingClientRect().width;
        const height = +svg.attr('height') || svg.node().getBoundingClientRect().height;

        const zoom = d3.zoom()
            .scaleExtent([1, 8])
            .translateExtent([[0, 0], [width, height * 1.5]])
            .on('zoom', (event) => {
                svg.select('g').attr('transform', event.transform);
            });

        svg.call(zoom);
        zoomRef.current = zoom;
    };

    useEffect(() => {
        setupZoom();
    }, []);

    useEffect(() => {
        const resetZoom = () => {
            const svg = d3.select(geoChartRef.current);
            svg.call(zoomRef.current.transform, d3.zoomIdentity);
        };

        resetZoom();
    }, [GeoData]);

    useEffect(() => {
        dispatch(addGeoName(headerTitle));
        // eslint-disable-next-line
    }, [activeTab]);

    useEffect(() => {
        if (geoChartRef.current) {
            const containerHeight = geoChartRef.current.offsetHeight;
            const newColorBoxHeight = Math.floor(containerHeight * 0.6);
            setColorBoxHeight(newColorBoxHeight);
        }
    }, [geoChartRef]);

    useFetchData(fetchGeoData, [
        useSelector(state => state.stackedWithContentSegmentSlice.selectedMonth),
        useSelector(state => state.pieChartOtpSegment.selectedSlice),
        useSelector(state => state.pie.selectedSlice),
        useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments),
        useSelector(state => state.segmentNameSlice.currentSegmentName),
        useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments),
        useSelector(state => state.okpdComboSelect.okpdComboData),
        useSelector(state => state.dateSlice.selectedDate),
        useSelector(state => state.productCode.selectedProduct),
        useSelector(state => state.activitySlice),
        useSelector(state => state.treeMapSlice.selectedSegments),
        useSelector(state => state.productCode.trimCode),
        useSelector(state => state.barLineChartMonth.selectedMonth),
        useSelector(state => state.organization.relatedINNs),
        useSelector(state => state.contractOkpd.selectedOkpd),
        useSelector(state => state.contractOkpd.trimCode),
        useSelector(state => state.contractMonth1Slice.selectedContractMonth),
        useSelector(state => state.ispOkpd.selectedOkpd),
        useSelector(state => state.organization.searchOrgINNINNs),
        useSelector(state => state.organization.searchSuppINNINNINNs),
        useSelector(state => state.searchSwitcher.position),
        useSelector(state => state.region.mode)
    ], 'geoChart');

    useEffect(() => {
        if (paths) {
            paths.forEach((path) => {
                const regionCode = path.getAttribute('data-code');
                if (memoizedActiveRegions.length === 0) {
                    path.style.opacity = '1';
                } else {
                    if (memoizedActiveRegions.includes(regionCode)) {
                        path.style.opacity = '1';
                    } else {
                        path.style.opacity = '0.3';
                    }
                }
            });
        }
    }, [paths, memoizedActiveRegions]);

    useEffect(() => {
        if (GeoData.length === 0) return;

        if (!paths) {
            const svgPaths = geoChartRef.current.querySelectorAll("path");
            setPaths(Array.from(svgPaths));
        }

        if (paths && GeoData.length > 0) {
            const logScale = d3.scaleLog()
                .domain([min + 1, max])
                .range([0, numSegments - 1]);

            paths.forEach((path) => {
                const regionCode = path.getAttribute('data-code');
                const regionData = GeoData.find((data) => data.label === regionCode);
                if (regionData) {
                    const regionValue = regionData.value;
                    const colorIndex = Math.round(logScale(regionValue));
                    const color = gradientColors[colorIndex];
                    path.style.fill = color;
                } else {
                    path.style.fill = "#f0f0f0";
                }

                path.addEventListener('mousemove', handleMouseMove);
                path.addEventListener('mouseout', handleMouseOut);
                path.addEventListener('click', handleClick);
            });

            return () => {
                paths.forEach((path) => {
                    path.removeEventListener('mousemove', handleMouseMove);
                    path.removeEventListener('mouseout', handleMouseOut);
                    path.removeEventListener('click', handleClick);
                });
            };

        }
        // eslint-disable-next-line
    }, [GeoData, paths, min, max]);

    const handleMouseMove = (event) => {
        const regionCode = event.target.getAttribute('data-code');
        const regionData = GeoData.find((data) => data.label === regionCode);
        if (!regionData) {
            hideTooltip();
            return;
        }
        const regionName = cityNamesMap[regionCode] || regionCode;
        const tooltipConfig = tooltipNames.GeoMap.Tabs[activeTab];
        const tooltipText = [
            { label: tooltipConfig.region, value: regionName },
            { label: tooltipConfig.value, value: formatCurrency(regionData.value) },
        ];
        showTooltip(event, tooltipText);
    };

    const handleMouseOut = () => {
        hideTooltip();
    };

    const handleClick = (event) => {
        cancelAllPendingRequests();
        const regionCode = event.target.getAttribute('data-code');
        const regionData = GeoData.find((data) => data.label === regionCode);
        if (regionData) {
            dispatch(toggleRegion(regionCode));
            const regionName = cityNamesMap[regionCode] || regionCode;
            dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: regionName }));
            dispatch(addGeoName(headerTitle));
        }
    };

    const getColorForRegion = (value) => {
        const logScale = d3.scaleLog()
            .domain([min + 1, max])
            .range([0, numSegments - 1]);
        const colorIndex = Math.round(logScale(value));
        return gradientColors[colorIndex];
    };

    const majorCitiesDataAndColors = GeoData
        .filter(d => Object.keys(cityNamesMap).includes(d.label))
        .map(city => {
            return {
                label: cityNamesMap[city.label],
                color: getColorForRegion(city.value),
                value: city.value
            };
        })
        .sort((a, b) => b.value - a.value);

    const handleLegendItemClick = (label) => {
        const regionCode = Object.keys(cityNamesMap).find(key => cityNamesMap[key] === label);
        if (regionCode) {
            dispatch(toggleRegion(regionCode));
            dispatch(addGeoName(headerTitle));
        }
    };

    return (
        <div className={`${style.container} ${memoizedActiveRegions.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: '85%' } : {}}>
            <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} ref={tooltipRef} />
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    activeMode={mode || 'cust'}
                    diagramName={headerTitle}
                    onZoomClick={onZoomClick}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'customer?') {
                            dispatch(setMode('cust'));
                            if (memoizedActiveRegions.length > 0) {
                                dispatch(clearActiveRegions());
                            }
                        } else if (mode === 'supplier?') {
                            dispatch(setMode('org'));
                            if (memoizedActiveRegions.length > 0) {
                                dispatch(clearActiveRegions());
                            }
                        }
                        else {
                            dispatch(setMode("cust"))
                        }
                    }}
                    diagramType="geo"
                />
            </div>
            <div className={style.header}>
                <Legend
                    diagramId={headerTitle}
                    data={majorCitiesDataAndColors}
                    dynamicRadius={75}
                    activeColors={majorCitiesDataAndColors.map(city => city.color)}
                    onLegendItemClick={handleLegendItemClick}
                    selectedSlice={selectedRegions}
                />
            </div>
            <div ref={geoChartRef} className={styles.mapAndScaleContainer} style={zoomedDiagram ? { height: "60vh" } : {}}>
                <MapSvg
                    className={styles['rf-map']}
                    viewBox={viewBox}
                    preserveAspectRatio="xMidYMid meet"
                    style={{ width: '90%', height: '90%' }}
                />
                <ColorScale min={min} max={max} height={colorBoxHeight} />
            </div>
        </div>
    );
};
