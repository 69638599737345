import { useState, useRef, useEffect } from 'react';

const useTooltip = () => {
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: [], showColon: true });
    const tooltipRef = useRef(null);

    const showTooltip = (event, text, showColon = true) => {
        if (!text || text.length === 0) {
            hideTooltip();
            return;
        }

        const tooltipSize = tooltipRef.current?.getBoundingClientRect() || { width: 0, height: 0 };
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text,
            showColon
        });
    };

    const hideTooltip = () => {
        setTooltip({ x: 0, y: 0, text: [], showColon: true });
    };

    return { tooltip, tooltipRef, showTooltip, hideTooltip };
};

export default useTooltip;
